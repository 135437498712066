.App {
  text-align: center;
  font-family: 'Source Sans Pro', arial, sans-serif;
  background-color: #f5f5f5;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.item-img {
  height: 50px;
  width: 50px;
  pointer-events: none;
}

.item-detail-img {
  height: 150px;
  width: 150px;
  pointer-events: none;
}

.thumnail-img {
  height: 50px;
  width: 50px;
}

.show-img {
  margin: 0px;
  display: block;
  overflow: hidden;
  height: 50vmin;
  background-color: transparent !important;
  @media (max-width: 480px) {
    width: 60vmin;
    height: 100%;
  }
}

.tools button {
  margin: 5px;
  padding: 5px;
  min-width: 30px;
}
.photo-dialog {
  margin: 0;
  @media (min-width: 480px) {
    margin: 5%;
  }
}
@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  margin-left: 10%;
  margin-right: 10%;
  /* min-height: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #272262;
}

.App-footer {
  text-align: right;
  margin-right: 13%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #272262;
  margin-top: 18px;
  margin-bottom: 30px;
}
.square-container {
  margin:5px !important;
  padding:5px !important;
}

.square-container-selected {
  margin:5px !important;
  padding:5px !important;
  border: 1px solid #272262;
}

.square {
  background: #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 50px;
  width: 50px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  cursor: pointer;
}

.App-link {
  color: #272262;
}

.textarea{
  width: 100%;
}

.input{
  width: 100%;
}

.button{
  background: #272262;
  margin-top: 0;
  width: 180px;
  height: 55px;
  @media (mix-width: 600px) {
    margin-top: 30;
    width: 110px !important;
    height: 45px !important;
  }
}

.package-list {
  position: relative;
  /* background-color: white; */
}

.package-list-row {
  background-color: #f5f5f5;
  margin-bottom: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.term{
  width:100%;
  min-width: 150px;
}

.title {
  font-weight: bolder !important;
}

.text-field-item {
  padding:10px !important;
}

.MuiButtonBase-root:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  background-color: grey;
 }

 .link-button {
   text-decoration: none !important;
   text-transform: none !important;
   background-color: #f5f5f5 !important;
   color: #272262 !important;
 }

.link-button-selected {
  text-decoration: none !important;
  text-transform: none !important;
  background-color: #f5f5f5 !important;
  color: #ff4301 !important;
}

.version {
  height: 20px;
  color: grey;
  font-size: 0.7em;
  text-align: center;
}

.Detail {
  padding: 10px 0px 0px 0px;
  min-width: 500px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}